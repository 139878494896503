<template>
  <div class="container">
    <div>
      <content-title :nav="nav"></content-title>
    </div>
    <div class="ontents">
      <div class="sec-title">兑换码生成列表</div>
    </div>
    <div class="search" style="display: flex;flex-wrap: wrap;" >
      <el-form :inline="true" :model="form" class="demo-form-inline">
        <el-form-item label="用户名">
          <el-input size="small" v-model="name"></el-input>
        </el-form-item>
        <el-form-item label="账号">
          <el-input size="small" v-model="phone"></el-input>
        </el-form-item>
        <!-- <el-form-item label="锦鲤码">
          <el-input v-model="couponCode"></el-input>
        </el-form-item> -->
        <el-form-item >
          <el-button size="small" type="primary" @click="onSubmit()"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
      <!-- <div class="btnfafang" style="margin-bottom:1rem;float:right">
        <el-button type="danger" size="small" @click="isFlag">生成</el-button>
        <el-button type="danger" @click="drawCode" size="small">批量领取</el-button>
      </div> -->
    </div>
    <el-button type="danger" size="small" @click="isFlag">生成</el-button>
    <!-- 批量领取 -->

    <!-- 列表 -->
    <div>
      <textarea id="input" style="position: fixed; left: -100%"></textarea>

      <div class="contentList">
        <div class="paging">
          <el-table
            border
            ref="multipleTable"
            :header-cell-style="{ background: '#EAEAEA', color: '#434343' }"
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%"
          >
            <el-table-column
              prop="id"
              align="center"
              label="id"
            ></el-table-column>
            <el-table-column
              prop="coupon_type"
              align="center"
              label="券类型"
            ></el-table-column>
            <el-table-column
              prop="create_count"
              align="center"
              label="张数"
            ></el-table-column>
            <!-- <el-table-column prop="send_id" align="center" width="200" label=""></el-table-column> -->
            <el-table-column prop="identity" align="center" label="发放来源">
              <template slot-scope="{ row }">
                <div v-if="row.identity.length < 5">
                  {{ row.identity }}
                </div>
                <div v-else>后台</div>
              </template>
            </el-table-column>
            <el-table-column prop="start_num" align="center" label="号段">
            </el-table-column>
            <el-table-column prop="status" align="center" label="状态">
              <template slot-scope="scope">
                <span v-if="scope.row.status == 1">未启用</span>
                <span v-if="scope.row.status == 2">已启用</span>
              </template>
            </el-table-column>
            <el-table-column prop="user_id" align="center" label="领取人">
            </el-table-column>
            <el-table-column
              prop="create_time"
              align="center"
              label="领卡时间"
            ></el-table-column>
            <el-table-column align="center" label="操作">
              <template slot-scope="scope">
                <!-- <el-button @click="copyCode(scope.row)" type="text" size="small">复制</el-button> -->
                <el-button
                  @click="toDetails(scope.row.id)"
                  type="text"
                  size="small"
                  >查看详情</el-button
                >
                <el-button
                  @click="open(scope.row)"
                  type="text"
                  size="small"
                  v-if="scope.row.status == 1"
                  >启用</el-button
                >
                <el-button
                  @click="close(scope.row)"
                  type="text"
                  size="small"
                  v-if="scope.row.status == 2"
                  >关闭</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <!--分页-->
          
  
        <paging-fy
          @currentPageChange="handleCurrentChange"
          :currentPage="currentPage"
          :total="total"
        ></paging-fy>
        </div>
      </div>
      <input
        id="copy_content"
        type="text"
        value=""
        style="position: absolute; top: 0; left: 0; opacity: 0; z-index: -10"
      />
    </div>
    <el-dialog :visible="groupListShow" width="90%" :before-close="handleClose">
      <el-table :data="coupon_list_" size="small">
        <el-table-column
          :formatter="fromTitle"
          prop="map.getUserInfo.nick_name"
          align="center"
          width="200"
          label="发放用户"
        ></el-table-column>
        <el-table-column
          :formatter="fromTitle"
          prop="map.getUserInfo.mobile"
          align="center"
          width="300"
          label="手机号"
        >
        </el-table-column>
        <el-table-column
          prop="start_num"
          align="center"
          width="200"
          label="卡号"
        >
        </el-table-column>
        <el-table-column
          prop="coupon_code"
          align="center"
          width="200"
          label="兑换码"
        ></el-table-column>
        <el-table-column prop="status" align="center" label="状态">
          <template slot-scope="scope">
            <span v-if="scope.row.status == 5">未启用</span>
            <span v-if="scope.row.status == 2">已启用</span>
            <span v-if="scope.row.status == 1">已领取</span>
            <span v-if="scope.row.status == 4">已过期</span>
            <span v-if="scope.row.status == 6">失效</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="create_time"
          align="center"
          width="300"
          label="发放时间"
        ></el-table-column>
        <el-table-column prop="" align="center" width="300" label="操作">
          <template slot-scope="scope">
            <el-button
              size="small"
              v-if="scope.row.status == 5"
              @click="openit(scope.row, { status: 2 })"
              >启用</el-button
            >
            <el-button
              size="small"
              v-if="scope.row.status == 2"
              @click="openit(scope.row, { status: 5 })"
              >关闭</el-button
            >
            <el-button
              size="small"
              v-if="
                scope.row.coupon_type == '尊享卡' &&
                scope.row.is_pay == true &&
                (scope.row.status == 2 || scope.row.status == 5)
              "
              @click="openit(scope.row, { is_pay: false })"
              >切换特殊尊享卡</el-button
            >
            <el-button
              size="small"
              v-if="
                scope.row.coupon_type == '尊享卡' &&
                scope.row.is_pay == false &&
                (scope.row.status == 2 || scope.row.status == 5)
              "
              @click="openit(scope.row, { is_pay: true })"
              >切换尊享卡</el-button
            >
            <el-button
              size="small"
              v-if="scope.row.status == 5 || scope.row.status == 2"
              type="danger"
              @click="openit(scope.row, 6)"
              >删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <!-- 生成锦鲤码 -->
    <el-dialog
      title="生成锦鲤码"
      :visible.sync="flag"
      :width="ks ? '90%' : '30%'"
      :before-close="handleClose"
    >
      <div class="login">
        <div class="popupcontents">
          <el-form ref="form" label-width="100px" style="padding-right: 40px">
            <el-form-item label="选择券">
              <el-select
                v-model="coupon"
                placeholder="请选择券"
                remote
                @focus="getCoupon"
                @change="getCouponType"
                value-key="id"
              >
                <el-option
                  v-for="(item, index) in coupon_list"
                  :key="index"
                  :label="item.name"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="特殊尊享卡" v-if="coupon.type == '尊享卡'">
              <el-checkbox v-model="is_pay"></el-checkbox>
            </el-form-item>
            <el-form-item label="发卡方">
              <el-select v-model="channels" placeholder="请选择发卡方">
                <el-option label="机构" value="机构"> </el-option>
                <el-option label="合作商" value="合作商"> </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="生成数量">
              <el-input
                v-model="grantNum"
                type="number"
                placeholder="请输入锦鲤码生成的数量"
              ></el-input>
              <span style="position: absolute; right: 40px">条</span>
            </el-form-item>
            <el-form-item label="起始号段">
              <el-input v-model="start_num"></el-input>
            </el-form-item>
            <el-form-item label="兑换码长度">
              <el-input
                v-model="coupon_length"
                type="number"
                placeholder="请输入锦鲤码生成的长度"
              ></el-input>
              <span style="position: absolute; right: 40px"></span>
            </el-form-item>
            <el-form-item label="有效期">
              <el-input
                v-model="overdue"
                type="number"
                placeholder="请输入锦鲤码的有效期"
              ></el-input>
              <span style="position: absolute; right: 40px">天</span>
            </el-form-item>
            <el-form-item>
              <el-button style="margin-left: 30px" @click="toclose"
                >取消</el-button
              >
              <el-button
                style="margin-left: 110px"
                type="primary"
                @click="generateCoupon"
                >确认</el-button
              >
            </el-form-item>
            <div class="btns"></div>
          </el-form>
        </div>
      </div>
    </el-dialog>
    <!-- 领取锦鲤码 -->
    <!-- <el-dialog
      title="确认领取锦鲤码"
      :visible.sync="show"
      width="30%"
      :before-close="handleClose"
    >
      <div class="login">
        <div style="margin: 20px 0">
          <el-button style="margin-left: 120px" @click="toclose"
            >取消</el-button
          >
          <el-button style="margin-left: 110px" type="primary" @click="lingqu"
            >确认</el-button
          >
        </div>
      </div>
    </el-dialog> -->
    <!-- 确认领取锦鲤码 -->
    <!-- <el-dialog
      title="领取锦鲤码"
      :visible.sync="codeShow"
      width="30%"
      :before-close="handleClose"
    >
      <div class="login">
        <textarea id="input" style="position:fixed;left:-100%"></textarea>
        <p style="font-size:2.5rem;text-align: center; color: #2f2f2f">
          {{ code }}
        </p>
      </div>
      <div style="margin: 20px 0">
        <el-button style="margin-left: 120px" @click="toclose">取消</el-button>
        <el-button style="margin-left: 110px" type="primary" @click="renew"
          >确认</el-button
        ><el-button type="text" style="margin-left:1.5rem" @click="copy()"
          >复制</el-button
        >
      </div>
    </el-dialog> -->
  </div>
</template>

<script>
export default {
  name: "LiveStreaming",
  components: {},
  data() {
    return {
      ss: 5,
      nav: {
        firstNav: "优惠券管理",
        secondNav: "生成兑换码",
      },
      tableData: [], //总数据
      flag: false,
      coupon_length: null,
      show: false,
      codeShow: false,
      code: "",
      id: [],
      groupListShow: false,
      user_id: "",
      total: 0,
      temp_id: null,
      channels: null,
      start_num: null,
      commodity_type: null,
      name: "",
      phone: "",
      coupon_list: [],
      coupon_id: null,
      coupon: {},
      is_pay: null,
      couponCode: "",
      grantNum: "",
      overdue: "", //添加条数
      sels: [], //选中数据组

      form: {
        user: "",
        date1: "",
      },
      coupon_list_: [],
      pageSize: 10,
      currentPage: 1,
      total: 0,
      dorw: false,
      ks: "",
    };
  },

  mounted() {
    this.ks = window.screen.width < 768;
    // this.GetNumbers();
    this.GetList();
    // this.qwe()
  },
  methods: {
    selectiondrawCode(selection) {
      //  console.log(selection,"当列数据111")
      this.sels = selection;
    },

    renew() {
      this.codeShow = false;
    },
    toDetails(id) {
      this.temp_id = id;
      let url = "/user/couponList/queryByMessage";
      this.$axios
        .get(url, {
          params: {
            send_id: id,
          },
        })
        .then((res) => {
          this.coupon_list_ = res.data.data.rows;
          this.coupon_list_total = res.data.data.total;
          this.groupListShow = true;
        });
    },
    getCouponType(v) {
      this.coupon = v;
      this.coupon_id = v.id;
      this.is_pay = null;
    },
    openit(row, option) {
      let url = "/user/couponList/update";
      this.$axios
        .post(url, {
          id: row.id,
          ...option,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({
              type: "success",
              message: "成功!",
            });
            this.GetList();
            this.toDetails(this.temp_id);
          } else {
            this.$message({
              type: "error",
              message: res.data.message,
            });
          }
        });
    },
    open(row) {
      let url = "/user/couponListSend/update";
      this.$axios
        .post(url, {
          id: row.id,
          status: 2,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({
              message: "修改成功!",
              type: "success",
            });
            this.GetList();
          } else {
            this.$message({
              message: res.data.message,
              type: "error",
            });
          }
        })
        .catch((res) => {});
    },
    close(row) {
      let url = "/user/couponListSend/update";
      this.$axios
        .post(url, {
          id: row.id,
          status: 1,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({
              message: "修改成功!",
              type: "success",
            });
            this.GetList();
          } else {
            this.$message({
              message: res.data.message,
              type: "error",
            });
          }
        })
        .catch((res) => {});
    },
    isFlag() {
      this.flag = true;
      //  console.log("弹框",this.flag)
    },
    filterChange(filterObj) {
      //  console.log(filterObj, "当列数据");
    },
    handleCurrentChange(val) {
      //  console.log(`当前页: `, val);
      this.currentPage = val;
      this.GetList();
    },
    copyCode(row) {
      //  console.log(row);
      this.id = [];
      this.code = row.coupon_code;
      this.id.push(row.id);
      this.user_id = row.user_id;
      this.copy();
    },
    // 复制
    copy() {
      let text = this.code;
      this.sels.forEach((e) => {
        text + e.coupon_code;
      });
      var input = document.getElementById("input");
      input.value = text; // 修改文本框的内容
      input.select(); // 选中文本
      document.execCommand("copy"); // 执行浏览器复制命令
      this.$message(
        {
          message: "复制成功",
          type: "success",
        },
        2000
      );
    },
    getCoupon() {
      let url = "/user/commoditycoupon/queryManagerListPage";
      this.$axios
        .get(url, {
          params: {
            status: 2,
            categories: "体育运动",
          },
        })
        .then((res) => {
          this.coupon_list = res.data.data.rows;
        });
    },
    fromTitle(row, column, cellValue) {
      if (cellValue === "" || cellValue == null) {
        return "暂无";
      } else {
        return cellValue;
      }
    },
    // 关闭弹框
    toclose() {
      this.flag = false;
      this.codeShow = false;
      this.show = false;
    },
    GetList() {
      let url = "/user/couponListSend/queryByMessage";
      this.$axios
        .get(url, {
          params: {
            // status: 2,
            pageSize: 10,
            currentPage: this.currentPage,
            // categories: '体育运动',
            // source: '体育宝',
          },
        })
        .then((res) => {
          this.tableData = res.data.data.rows;
          this.total = res.data.data.total;
        });
    },
    // 添加优惠券
    generateCoupon() {
      if (this.grantNum == "" && this.overdue == "") {
        this.$message(
          {
            message: "信息不完整",
            type: "error",
          },
          2000
        );
      } else {
        let data = {
          number: this.grantNum,
          id: this.coupon_id,
          coupon_length: this.coupon_length,
          user_id: JSON.parse(localStorage.getItem("managerInfo")).user_id,
          duration: this.overdue,
          commodity_type: this.commodity_type,
          start_num: this.start_num,
          channels: this.channels,
          is_pay: !this.is_pay,
        };

        this.$axios
          .post("/user/commoditycoupon/insertCouponList", data)
          .then((res) => {
            this.GetList();
            this.toclose();
            let message = res.data.message;
            alert("添加" + message);
            this.grantNum = "";
            this.overdue = "";
            this.coupon_id = null;
          });
      }
    },
    // 领取
    // lingqu() {
    //   this.show = false;
    //   this.codeShow = true;
    //   let data = {
    //     ids: this.id,
    //     user_id: localStorage.getItem("mechanism_id")
    //   };
    //   this.$axios.post("/user/couponList/updateStatus", data).then(res => {
    //     this.GetList();
    //     this.$message(
    //       {
    //         message: "领取成功",
    //         type: "success"
    //       },
    //       2000
    //     );
    //   });
    // },
    // 批量领取
    drawCode() {
      this.dorw = true;
      //  console.log(this.sels)
      if (this.sels == "") {
        this.$message(
          {
            message: "请选中锦鲤码数据",
            type: "error",
          },
          2000
        );
      } else {
        this.show = true;
        this.id = [];
        let codes = [];
        this.sels.forEach((e) => {
          this.id.push(e.id);
          codes.push(e.coupon_code);
        });
        this.code = codes.join(",");
      }
    },
    handleClose(done) {
      this.addDialog = false;
      this.groupListShow = false;
      this.updateDialog = false;
      this.form = {};
      this.temp = {};
      done();
    },
    onSubmit() {
      if (this.phone == "" && this.name == "") {
        this.GetList();
      } else {
        this.$axios
          .get(
            "/user/couponList/queryByMessage?coupon_type=lucky_charm_key" +
              "&login_name=" +
              this.phone +
              "&nick_name=" +
              this.name
          )
          .then((res) => {
            console.log(res);
            this.tableData = res.data.data.rows;
            this.total = res.data.data.total;
            this.tableData.forEach((e) => {
              let codeFive = e.coupon_code.split("").slice(0, 4).join("");
              let code = codeFive + "****";
              e.code = code;
            });
          });
      }
    },
  },
};
</script>

<style >

</style>
